/*
 * Page: Lock Screen
 * -----------------
 */

.transform(@action) {
  -webkit-transform: @action;
  -moz-transform: @action;
  -ms-transform: @action;
  -o-transform: @action;
  transform: @action;
}

/* ADD THIS CLASS TO THE <BODY> TAG */
.lockscreen {
  background: @body-bg;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.lockscreen-logo {
  font-size: 35px;
  text-align: center;
  margin-bottom: 25px;
  font-weight: 300;
  height: 10vh;
  min-height: 55px;
  a {
	color: #444;
  }

  &:after {
	content: "";
	background-image: url(../img/tni-logo.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	height: 10vh;
	position: absolute;
	top: 0;
	left: 5vw;
	min-height: 55px;
	width: 90vw;
  }
}

.lockscreen-wrapper {
  margin: 0 auto;
}

.lockscreen-header {
  position: relative;
  text-align: center;
  background: @light-blue;
  height: 40vh;
  min-height: 220px;

  .lockscreen-text {
	position: absolute;
	left: 50%;
	.transform(translateX(-50%));
	bottom: 70px;
	color: #ffffff;
	height: 50%;
	width: 100vw;
  }
}

/* User name [optional] */
.lockscreen .lockscreen-name {
  font-size: 18px;
}

/* Will contain the image and the sign in form */
.lockscreen-item {
  .border-radius(4px);
  padding: 0;
  background: #fff;
  position: relative;
  margin: 10px auto 30px auto;
  width: 290px;
}

@-moz-keyframes spin {
  0% {
	.transform(rotate(0deg));
  }
  100% {
	.transform(rotate(360deg));
  }
}

@-webkit-keyframes spin {
  0% {
	.transform(rotate(0deg));
  }
  100% {
	.transform(rotate(360deg));
  }
}

@keyframes spin {
  0% {
	.transform(rotate(0deg));
  }
  100% {
	.transform(rotate(360deg));
  }
}

/* User image */
.lockscreen-image {
  .lockscreen-profile-empty {
	background-image: url(../img/generic-avatar.png) !important;
	background-size: 114px 114px !important;
  }
  .lockscreen-profile {
	height: 114px;
	width: 114px;
	z-index: 3;
	position: absolute;
	top: -57px;
	.transform(translateX(-50%));
	background-color: #e2e8f5;
	background-size: 114px 114px;
  }
  .lockscreen-profile-spin {
	height: 122px;
	width: 122px;
	-webkit-animation: spin 2s linear infinite;
	-moz-animation: spin 2s linear infinite;
	animation: spin 2s linear infinite;
	z-index: 2;
	position: absolute;
	top: -61px;
	left: -61px;
	border-radius: 50%;
	background-image: url(../img/loginspin.png);
	background-size: 122px 122px;
  }
  .lockscreen-profile-placeholder {
	width: 122px;
	height: 122px;
	border: 6px solid @accent-blue;
	z-index: 1;
	position: absolute;
	top: -61px;
	.transform(translateX(-50%));
  }
  .lockscreen-profile-border {
	height: 122px;
	width: 122px;
	border: 6px solid #80b5d3;
	z-index: 1;
	position: absolute;
	left: -1px;
	top: -2px;
  }
  > div {
	position: relative;
  }

  position: absolute;
  left: 50vw;
  bottom: 0;
}

/* Contains the password input and the login button */
.lockscreen-credentials {
	height: 200px;
	padding-top: 90px;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;

	form {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.lockscreen-input {
		.google-btn {
			margin: 25px;
			width: 80%;
			min-width: 184px;
			max-width: 184px;
			height: 42px;
			background-color: #fcfcfc;
			border-radius: 2px;
			box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .2);
			cursor: pointer;
			cursor: hand;
			align-self: center;
			user-select: none;
			transition: all 400ms ease 0s;
			display: flex;
			&.disabled {
				background-color: #d2d2d2;
				cursor: not-allowed;
				.btn-text {
					margin: initial;
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
				}

			}

			.google-icon-wrapper {
				position: absolute;
				margin-top: 1px;
				margin-left: 1px;
				width: 40px;
				height: 40px;
				border-radius: 2px;
				user-select: none
			}

			.google-icon-svg {
				position: absolute;
				margin-top: 11px;
				margin-left: 11px;
				width: 18px;
				height: 18px;
				user-select: none
			}

			.btn-text {
				margin: 11px 14px 40px 40px;
				color: #757575;
				font-size: 14px;
				letter-spacing: .2px;
				font-family: Roboto;
				user-select: none;
			}

			&:not(.disabled):hover {
				box-shadow: 0 3px 8px rgba(117, 117, 117, .5);
				user-select: none
			}

			&:not(.disabled):active {
				box-shadow: 0 1px 1px #757575;
				background: #F8F8F8;
				color: #fff;
				user-select: none
			}
		}


	}
  /*
  .btn {
	background-color: #fff;
	border: 0;
	padding: 0 10px;
  }*/
}

.lockscreen-footer {
  width: 300px;
  text-align: center;
}

.lockscreen-infotext {
	text-align: center;
  .infotext {
	border-radius: 0 0 2px 2px;
	padding: 5px 5px 5px 13px;
	width: 200px;
	height: 30px;
  }
  .info {
	color: #333333;
  }
  .error {
	color: #dd4b39;
  }
}
