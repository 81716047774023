/*
 * Component: Toasty
 * -----------------------
 */

.toasty {
  text-align: left;
  position: fixed;
  width: 160px;
  right: -10px;
  bottom: 60px;
  background: cornflowerblue;
  padding: 5px 5px 5px 30px;
  color: #fff;
  border:  1px solid #000;
  border-top-left-radius: 16px !important;
  border-bottom-left-radius: 16px !important;
  box-shadow: 0 4px 8px #ccc !important;
  z-index: 1030;
}