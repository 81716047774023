/*
 * Component: Control sidebar. By default, this is the right sidebar.
 */
//The sidebar's background control class
//This is a hack to make the background visible while scrolling
.control-sidebar-bg {
  position: fixed;
  z-index: 1000;
  bottom: 0;
}

//Transitions
.control-sidebar-bg,
.control-sidebar {
  top: 0;
  right: -@control-sidebar-width;
  width: @control-sidebar-width;
  .transition(right @transition-speed ease-in-out);
}

//The sidebar
.control-sidebar {
  position: absolute;
  padding-top: @navbar-height;
  z-index: 1010;
  //Fix position after header collapse
  @media (max-width: @screen-sm) {
    padding-top: @navbar-height + 50;
  }
  //Tab panes
  > .tab-content {
    padding: 10px 15px;
  }
  //Open state with slide over content effect
  &.control-sidebar-open {
    &,
    + .control-sidebar-bg {
      right: 0;
    }
  }

  input,
  select {
    color: #000000;
    width: 168px;
  }

  fieldset {
    display: block;
    padding: 6px 0 5px 6px;
    margin-bottom: 6px;
    border: 1px solid #666666;
  }
}

//Open without slide over content
.control-sidebar-open {
  .control-sidebar-bg,
  .control-sidebar {
    right: 0;
  }
  @media (min-width: @screen-sm) {
    .content-wrapper,
    .right-side,
    .main-footer {
      margin-right: @control-sidebar-width;
    }
  }
}

//Control sidebar tabs
.control-sidebar-tabs {
  > li {
    &:first-of-type > a {
      margin-left: 1px;
      &,
      &:hover {
        border-left-width: 0 !important;
      }
    }
    > a {
      .border-radius(0) !important;
      //Hover and active states
      &,
      &:hover {
        border-top: none;
        border-right: none;
        border-left: 1px solid transparent !important;
        border-bottom: 1px solid transparent !important;
      }
      .icon {
        font-size: 16px;
      }
    }
    //Active state
    &.active {
      > a {
        &,
        &:hover,
        &:focus,
        &:active {
          border-top: none !important;
          border-right: none !important;
          border-bottom: none !important;
        }
      }
    }
  }
  //Remove responsiveness on small screens
  @media (max-width: @screen-sm) {
    display: table;
    > li {
      display: table-cell !important;
    }
  }
}

//Headings in the sidebar content
.control-sidebar-heading {
  font-weight: 400;
  font-size: 16px;
  padding: 10px 0;
  margin-bottom: 10px;
}

//Subheadings
.control-sidebar-subheading {
  display: block;
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
}

//Control Sidebar Menu
.control-sidebar-menu {
  list-style: none;
  padding: 0;
  margin: 0 -15px;
  > li > a {
    .clearfix();
    display: block;
    padding: 10px 15px;
    > .control-sidebar-subheading {
      margin-top: 0;
    }
  }
  .menu-icon {
    float: left;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    text-align: center;
    line-height: 35px;
  }
  .menu-info {
    margin-left: 45px;
    margin-top: 3px;
    > .control-sidebar-subheading {
      margin: 0;
    }
    > p {
      margin: 0;
      font-size: 11px;
    }
  }
  .progress {
    margin: 0;
  }
}

//Dark skin
.control-sidebar-dark {
  color: @sidebar-dark-color;
  // Background
  &,
  + .control-sidebar-bg {
    background: @sidebar-dark-bg;
  }
  // Sidebar tabs
  .control-sidebar-tabs {
    border-bottom: darken(@sidebar-dark-bg, 3%);
    > li {
      > a {
        background: darken(@sidebar-dark-bg, 5%);
        color: @sidebar-dark-color;
        //Hover and active states
        &,
        &:hover {
          border-left-color: darken(@sidebar-dark-bg, 7%) !important;
          border-bottom-color: darken(@sidebar-dark-bg, 7%) !important;
        }
        &:hover,
        &:focus,
        &:active {
          background: darken(@sidebar-dark-bg, 3%);
        }
      }
      //Active state
      &.active {
        > a {
          &,
          &:hover,
          &:focus,
          &:active {
            background: @sidebar-dark-bg;
            color: #fff;
          }
        }
      }
    }
  }
  //Heading & subheading
  .control-sidebar-heading,
  .control-sidebar-subheading {
    color: #fff;
  }
  //Sidebar list
  .control-sidebar-menu {
    > li {
      > a {
        &:hover {
          background: @sidebar-dark-hover-bg;
        }
        .menu-info {
          > p {
            color: @sidebar-dark-color;
          }
        }
      }
    }
  }
}

//Light skin
.control-sidebar-light {
  color: lighten(@sidebar-light-color, 10%);
  // Background
  &,
  + .control-sidebar-bg {
    background: @sidebar-light-bg;
    border-left: 1px solid @gray;
  }
  // Sidebar tabs
  .control-sidebar-tabs {
    border-bottom: @gray;
    > li {
      > a {
        background: darken(@sidebar-light-bg, 5%);
        color: @sidebar-light-color;
        //Hover and active states
        &,
        &:hover {
          border-left-color: @gray !important;
          border-bottom-color: @gray !important;
        }
        &:hover,
        &:focus,
        &:active {
          background: darken(@sidebar-light-bg, 3%);
        }
      }
      //Active state
      &.active {
        > a {
          &,
          &:hover,
          &:focus,
          &:active {
            background: @sidebar-light-bg;
            color: #111;
          }
        }
      }
    }
  }
  //Heading & subheading
  .control-sidebar-heading,
  .control-sidebar-subheading {
    color: #111;
  }
  //Sidebar list
  .control-sidebar-menu {
    margin-left: -14px;
    > li {
      > a {
        &:hover {
          background: @sidebar-light-hover-bg;
        }
        .menu-info {
          > p {
            color: lighten(@sidebar-light-color, 10%);
          }
        }
      }
    }
  }
}