//Special font for barchart minimal icon
@font-face {
  font-family: 'barchartmin';
  src:  url('fonts/barchartmin/barchartmin.eot?vovriu');
  src:  url('fonts/barchartmin/barchartmin.eot?vovriu#iefix') format('embedded-opentype'),
  url('fonts/barchartmin/barchartmin.ttf?vovriu') format('truetype'),
  url('fonts/barchartmin/barchartmin.woff?vovriu') format('woff'),
  url('fonts/barchartmin/barchartmin.svg?vovriu#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon-bar-chart {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'barchartmin' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &:before {
	content: "\e900";
  }
}


/*** Loading Ellipsis ***/
/***
 Use:
 <div class="loading-ellipsis reverse"><span>.</span><span>.</span><span>.</span></div>
 ***/
@keyframes blinkOpaque {
  0% {
	opacity: .2;
  }
  20% {
	opacity: 1;
  }
  100% {
	opacity: .2;
  }
}

.loading-ellipsis {
  font-weight: bold;
  span {
	animation-name: blinkOpaque;
	animation-duration: 1.4s;
	animation-iteration-count: infinite;
	animation-fill-mode: both;
	&:nth-child(2) {
	  animation-delay: .2s;
	}
	&:nth-child(3) {
	  animation-delay: .4s;
	}
  }
  &.reverse span {
	&:nth-child(3) {
	  animation-delay: 0s;
	}
	&:nth-child(1) {
	  animation-delay: .4s;
	}
  }
}
/*** End Loading Ellipsis ***/


.dim-70 {
  opacity: .7;
  &.hover-opaque:hover {
	opacity: 1;
  }
}

.dim-40 {
  opacity: .4;
  &.hover-opaque:hover {
	opacity: 1;
  }
}

.clickable {
  cursor: pointer;
}

/*	Check Hours */

.CheckHours .CheckHoursCalendar {
  text-align: center;

}

.CheckHours .CheckHoursCalendar table {
  border: 0px outset black;
  border-collapse: collapse;
  border-spacing: 0px;
  position: relative;
  white-space: nowrap;
  overflow-y: scroll;
  font-size: 8pt;
  vertical-align: top;
  width: 100%;
  table-layout: fixed;

}

.CheckHours .CheckHoursCalendar div {
  padding: 0px;
  margin: auto;
  width: 100%;
}

.CheckHours .CheckHoursCalendar td {
  border: 1px solid #666;
  border-collapse: collapse;
  border-spacing: 0px;
  padding: 0px;
  height: 80px;
  margin: 0px;
  top: 0px;
  white-space: nowrap;
  vertical-align: top;
  overflow: hidden;
  display: table-cell;
}

.CheckHours .CheckHoursCalendar .CheckHoursCalendarHeader td {
  height: 10px !important;
  font-weight: normal;
  font-size: larger;
  text-align: left;
  text-indent: 4px;

}

.CheckHours .CheckHoursCalendar table:hover {
  cursor: pointer;
}

.CheckHours .CheckHoursCalendar .CheckHoursCalendarTotalColumn {
  width: 85px !important;
  background: rgba(223, 223, 223, 0.100);
  vertical-align: middle;
  font-size: larger;

}

.CheckHours .CheckHoursCalendar .CheckHoursCalendarDateToday {
  background: #979797;
  color: #eee;
  opacity: 1;
}

.CheckHours .CheckHoursCalendar .CheckHoursCalendarDatePriorMonth {
  background: #eee;
  opacity: .5;
}

.CheckHours .CheckHoursCalendar .CheckHoursCalendarDateNextMonth {
  background: #eee;
  opacity: .5;
}

.CheckHours .CheckHoursCalendar .totalHours {
  font-size: large;
  margin-top: 8px;

}

.CheckHours .CheckHoursCalendar .CheckHoursCalendarLegend ul {
  padding: 0px;
}

.CheckHours .CheckHoursCalendar .CheckHoursCalendarLegend li {
  vertical-align: middle;
  margin: auto;
  overflow: hidden;
  padding: 3px 13px;
  text-shadow: 0 0 3px rgba(255, 255, 255, 0.4);
  white-space: nowrap;
}

.CheckHours .CheckHoursCalendar .CheckHoursCalendarLegend li:hover {
  cursor: initial;
}

.CheckHours .CheckHoursCalendar .CheckHoursCalendarLegend .CheckHoursCalendarLegendColorBox {
  width: 14px;
  height: 14px;
  vertical-align: middle;
  display: inline-block;
  border: 1px solid black;
}

.CheckHours .CheckHoursCalendar .calendarDailyDate {
  text-align: left;
  text-indent: 4px;
}

.CheckHours .CheckHoursMenuMonthName {
  background-color: #fff;
  border: 1px solid #ccc;
  color: #333;

  display: inline-block;
  padding: 6px 12px;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;

}

.CheckHours .panel-heading {
  padding: 10px 5px 0px 5px;
}

.CheckHours .CheckHoursMenu .glyphicon {
  font-size: medium;
}

.CheckHours .CheckHoursMenu .navbar-nav li {
  padding-left: 5px;
  padding-right: 5px;
}

.CheckHours .CheckHoursMenuDateButton {
  color: #abaab0;
}

.CheckHours .CheckHoursMenuHelpButton {
  color: #337ab7;
}

.CheckHours .CheckHoursMenuDateLabel {
  color: #abaab0;
}

.CheckHours .CheckHoursMenuHelp ul {
  list-style: none;
}

.CheckHours .CheckHoursMenuHelp li {
  padding-right: 5px;
}

.CheckHours .CheckHoursMiniSummary {
  height: 180px;
}

.CheckHours .CheckHoursMiniSummaryLabel {
  color: #000000;
  font-weight: 500;
}

.CheckHours .CheckHoursMiniSummaryLabelValue {
  color: #666666;
}

.CheckHours .CheckHoursMiniSummaryPayPeriodDates {
  font-weight: 500;
  font-size: larger;
  text-decoration: underline
}

/*	END Check Hours */

// Focus specific classes go here

.timegraph .base-line {
  .domain {
	stroke-width: 2;
  }
  .tick text {
	fill: #929292;
  }
}

.area-scatter-chart {
  .base-line-labels {
	.domain {
	  visibility: hidden;
	}
	.tick {
	  line {
		visibility: hidden;
	  }
	  text {
		fill: #929292;
	  }
	}
  }
}

.fixed-icon-col {
  th&, th& {
	width: 20px;
  }
}

table.table-fixed {
  table-layout: fixed;
}

.parent-hover-show .child-hover-show {
  visibility: hidden;
}

.parent-hover-show:hover .child-hover-show {
  visibility: visible;
}

.child-hover-show:hover {
  visibility: visible;
}

.content-padding {
  padding: 10px;
  overflow-y: auto;
}

.mini-padding {
  padding: 5px;
  overflow: hidden;
}

.small-field {
  margin-top: 5px !important;
}

.min-width {
  min-width: 100px;
  width: auto !important;
}

.hidden-row {
  padding: 0 !important;
}

.tiny-padding {
  padding: 2px !important;
}

.some-what-small {
  margin: 2px 0 0 0 !important;
}

.super-small {
  margin: 0 !important;
}

.caption {
  display: table-caption;
  text-align: center;
}

.hr-small {
  margin-bottom: 5px;
  margin-top: 5px;
}

.table {
  tbody > tr {
	td {
	  .success {
		background-color: #DFF0D8 !important;
	  }
	  .error {
		background-color: #F2DEDE !important;
	  }
	  .warning {
		background-color: #FCF8E3 !important;
	  }
	  .info {
		background-color: #D9EDF7 !important;
	  }
	}

	:hover {
	  td {
		.success {
		  background-color: #D0E9C6 !important;
		}
		.error {
		  background-color: #EBCCCC !important;
		}
		.warning {
		  background-color: #FAF2CC !important;
		}
		.info {
		  background-color: #C4E3F3 !important;
		}
	  }
	}
  }
}

.results tr[visible="false"], .no-result {
  display: none;
}

.results tr[visible="true"] {
  display: table-row;
}

.counter {
  padding: 8px;
  color: #CCCCCC;
}

.sales-portal {
  .sales-form {
	max-height: 400px;
	overflow-y: auto;
  }

  .customer-form {
	border: 5px;
	padding: 8px;
  }

  .form-submit {
	font-size: 8pt;
	overflow-x: hidden;
	overflow-y: auto;
  }

}

.edit-in-place {
  cursor: pointer;
}

.edit-in-place-blocked {
  cursor: default;
}

.edit-in-place input {
  display: none;
}

.edit-in-place.active span {
  display: none;
}

.edit-in-place.active input {
  display: inline-block;
}

.edit-in-place.active select {
  display: inline-block;
}

.edit-in-place select {
  display: none;
}

.gross-profit-report .irs-bar {
  background: transparent;
}

.gross-profit-report .irs-bar-edge {
  background: transparent;
}

.friend-pending {

}

.text-lightgrey {
  color: lightgrey;
}

.cursor-default {
  cursor: default;
}

.cursor-hand {
  cursor: pointer;
}

.cursor-override {
  cursor: default !important;
}

.hide-graph {
  display: none;
  visibility: hidden !important;
  max-height: 0;
  min-height: 0;
  * {
	visibility: hidden !important;
  }
}

.collapsible-sub-box {
  box-shadow: 2px 6px 10px 1px #ddd;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  td & {
	margin-top: -8px;
  }
}

.no-margin-bottom {
  margin-bottom: 0;
  &.force {
	margin-bottom: 0 !important;
  }
}
.minimal-margin-bottom {
  margin-bottom: 5px;
  &.force {
	margin-bottom: 5px !important;
  }
}

// Stripes 2 rows
table.table-striped-double {
  &.table-hover > tbody {
	> tr:nth-last-child(4n), > tr:nth-last-child(4n - 1) {
	  &:hover {
		background-color: #f5f5f5;
	  }
	  &.no-hover:hover {
		background-color: #f9f9f9;
	  }
	}
  }
  > tbody {
	> tr:nth-last-child(4n), > tr:nth-last-child(4n - 1) {
	  background-color: #f9f9f9;
	}
  }
}

table.table-hover > tbody > tr.no-hover:hover {
  background-color: inherit;
}

.bg-inherit {
  background-color: inherit;
}

.text-italic {
  font-style: italic;
}

.collapsible {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  &.no-animation {
	transition: max-height 0s !important;
  }
  &.in {
	max-height: 999px;
	transition: max-height 1s ease-in-out;
  }
}

.input-group.radio-text-group {
  .input-group-addon {
	padding: 0 7px 0 0;
	border: none;
  }
}

svg.axis {
  overflow: visible;
  &.customTextColor > g {
	fill: inherit;
	> g {
	  fill: inherit;
	  > text{
		fill: inherit;
		stroke: none;
	  }
	  stroke: inherit;
	}
  }
  &.noDomain > g > .domain {
	visibility: hidden;
  }
  &.noTickLines > g > g > line {
	visibility: hidden;
  }

}

td {
  div, span {
	&.cell-overflow-container {
	  overflow: hidden;
	}
	&.hidden-overflow-cell-text {
	  overflow: hidden;
	  text-overflow: ellipsis;
	  max-width: 10vw;
	  white-space: nowrap;
	  &.show-on-hover:hover {
		overflow: visible;
		white-space: normal;
	  }
	}
  }
}

.area-scatter-chart svg.left-axis{
  overflow: visible;
  g {
	.domain {
	  visibility: hidden;
	}
	g {
	  text {
		font-size: 7px;
		fill: #929292;
	  }
	  text:first-of-type {
		font-size: 10px;
	  }
	  line {
		stroke: #929292;
	  }
	}
  }
}

/* set position dynamically */
.position-relative {
  position: relative;
}

.position-absolute {
  position: relative;
}
/* end set position dynamically */

.margin-after {
  margin-bottom: 10px;
}

/* table sortable */
.table-hover-style {
  background-color: #F5F5F5;
}

table.table-column-striped {
  > tbody, > thead {
	> tr {
	  > :nth-child(odd) {
		background-color: #FFFFFF;
		&.hovered {
		  .table-hover-style;
		}
	  }
	  > :nth-child(even) {
		background-color: #f9f9f9;
		&.hovered {
		  .table-hover-style;
		}
	  }
	  > .hovered {
		.table-hover-style;
	  }
	}
  }
}
/* end table sortable */

.table-sortable > thead > tr > th::after {
  font-family: FontAwesome;
  padding-left: 5px;
}
.table-sortable > thead > tr > th.sort-false::after {
  font-family: FontAwesome;
  padding-left: 5px;
}
.table-sortable > thead > tr > th.sort-true::after {
  font-family: FontAwesome;
  padding-left: 5px;
}
.table-sortable > thead > tr > th::after {
  content: "\f0dc";
  color: #ddd;
}
.table-sortable > thead > tr > th.sort-false::after {
  content: "\f0de";
  color: #767676;
}
.table-sortable > thead > tr > th.sort-true::after {
  content: "\f0dd";
  color: #767676;
}
.table-sortable > thead > tr > th {
  cursor: pointer;
  position: relative;
  background-image: none;
}
.table-sortable > thead > tr > th.no-sort-icon {
  cursor: default;
  &::after {
	visibility: hidden;
	display: none;
  }
}

.text-white {
  color: white;
}

.error-box {
  padding: 15px;
}

.error-details {
  max-height: 200px;
  overflow: auto;
  white-space: pre-wrap;
}

/* filter classes */

fieldset.filter-grouping {
  border: 1px solid #3c8dbc !important;
  padding: 0 1.4em 1.4em 1.4em !important;
  margin: 0 0 0.5em 0 !important;
  -webkit-box-shadow:  0px 0px 0px 0px #000;
  box-shadow:  0px 0px 0px 0px #000;
}

legend.filter-grouping {
  color: #3c8dbc !important;
  font-size: 1.2em !important;
  font-weight: bold !important;
  text-align: left !important;
  margin-bottom: 10px;
  width:auto;
  padding:0 10px;
  border-bottom:none;
}

.label-filter {
  color: #444;
  padding: 0 4px 0 4px;
  margin-right: 4px;
  background-color: #fff;
  border: 1px solid #3e90ba;
  font-size: 12px;
}
.filter-boring {
  margin-left: 4px;
  margin-right: 4px;
}

.header-filter {
  font-size:17px;
  padding: 0 4px 0 4px;
  margin:0 0 0 8px; border: 1px solid #fff;
  border-radius:5px;
}

.filter-main-container {
  min-height:25px;
  position:relative;
  color: #384a51;
  height:auto;
  background-color: #e8f7ff;
  padding: 0 0 0 0;
  border-bottom: 2px solid #3e90ba;
}

.filter-group-box {
  margin-top: 15px;
  margin-bottom: 10px;
}

.tag-container {
  padding:1px 0 2px 10px;
  margin-left: 15px;
  text-align:left;
  border-bottom-width: 5px;
}

.filter-toggle {
  font-weight:bold;
  position:absolute;
  left:10px;
  top:0;
}

.filter-form-group {
  margin-bottom: 7px;
}
.filter-form-header {
  margin-bottom: 2px;
}
/* end filter classes */

/* PDT classes */
.label-pdt {
  position:relative;
  color: #444;
  padding: 0 4px 0 1px;
  margin-right: 4px;
  background-color: #f5f5f5;
  border: 0 solid #d2d7df;
  font-size: 12px;
}
.label-pdt-child {
  position:relative;
  color: #444;
  padding: 0 4px 0 4px;
  margin-right: 4px;
  background-color: #f5f5f5;
  border: 1px solid #3379b7;
  font-size: 12px;
  margin-left: 14px!important;
  border-top-width: 0!important;
  border-right-width: 0!important;
  border-bottom-width: 0!important;
  border-left-width: 0!important;
}
.fake-textarea {
  -webkit-appearance: textarea;
  resize: auto;
  border: 1px solid #f5f5f5;
  background-color: #f5f5f5;
  margin-bottom: 5px;
  padding: 3px 3px 3px 3px;
  min-height: 380px;
}
.label-icons {
  font-size: 10px;
  position: absolute;
  top: 1px;
  right: -8px;
}
.sidebar-sticky {
  display:inline-block;
  position: sticky;
  top:5px;
}
.input-pdt{
  min-width: 140px;
}
.pdt-notes-label {
  color:#b6beca;
}
.pdt-notes-divider {
  Border-top: 1px solid #d0d0d0;
  margin-top: 5px;
  margin-bottom: 5px;
}
.pdt-notes-block {
  padding: 0px 2px;
  border: 2px solid transparent;
}
.pdt-notes-block[contenteditable]:focus {
  outline: 0 solid #b6beca;
  border: 2px solid #b6beca;
  border-radius:3px;
}
.pdt-block-info {
//  min-height:160px;
}
.pdt-block-troubleshooting {
//  min-height:160px;
}
.pdt-block-additional-notes {
//  min-height:100px;
}
.pdt-block-escalation {
//  min-height:60px;
}
/* end PDT */

/* General use classes */
.small-scroll {
  &::-webkit-scrollbar {
	width: 5px;
  }

  &::-webkit-scrollbar-track {
	box-shadow: inset 0 0 1px #e4e4e4a1;
	border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
	background: #DDDDDD;
	border-radius: 5px;
  }

  &:hover::-webkit-scrollbar-thumb {
	background-color: #888888;
  }

  &::-webkit-scrollbar-thumb:hover {
	background: #888888;
  }
}

.minimal-line-height, .minimal-line-height.btn {
  line-height: 1;
}

.with-right-margin {
  margin-right:5px;
}
.with-bottom-margin {
  margin-bottom:5px;
}
.with-left-margin {
  margin-left:5px;
}
.with-top-margin {
  margin-top:5px;
}

.profile-pic-placeholder {
  background-color: #e2e8f5;
  height: 55px;
  width: 55px;
  border-radius: 50%;
  align-self: center;
  justify-self: center;
  background-image: url(../img/generic-avatar.png);
  background-size: contain;
  background-position-x: 5px;
  border: #80b5d3 2px solid;
}

/* end General use classes */
