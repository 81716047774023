/*
 * Skin: Blue
 * ----------
 */
@import "../../../css/bootstrap/bootstrap.min.css";
@import "../../../css/fontawesome/css/font-awesome.min.css";
@import "../../../css/ionicons/css/ionicons.min.css";
@import "../AdminLTE.less";
@import "../../bootstrap/mixins.less";
@import "../../bootstrap/variables.less";
@import "../variables.less";
@import "../mixins.less";

.skin-blue {
  //Navbar
  .main-header {
	.navbar {
	  .navbar-variant(@light-blue; #fff);
	  .sidebar-toggle {
		color: #fff;
		&:hover {
		  background-color: darken(@light-blue, 5%);
		}
	  }
	  @media (max-width: @screen-header-collapse) {
		.dropdown-menu {
		  li {
			&.divider {
			  background-color: rgba(255, 255, 255, 0.1);
			}
			a {
			  color: #fff;
			  &:hover {
				background: darken(@light-blue, 5%);
			  }
			}
		  }
		}
	  }
	}
	//Logo
	.logo {
	  .logo-variant(darken(@light-blue, 5%));
	}

	li.user-header {
	  background-color: @light-blue;
	}
  }

  //Content Header
  .content-header {
	background: transparent;
  }

  //Create the sidebar skin
  .skin-dark-sidebar(@light-blue);
}

.skin-blue.layout-top-nav .main-header > .logo {
  .logo-variant(@light-blue);
}
